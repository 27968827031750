// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Adornment.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Adornment.tsx");
  import.meta.hot.lastModified = "1726176605631.3848";
}
// REMIX HMR END

import { cva } from 'class-variance-authority';
import { useMemo } from 'react';
import { cn } from '~/utils/cn';

// TODO: Redo adornments so they aren't absolutely positioned
// LInear ticket: https://linear.app/numbersstation/issue/NS-8363
const paddingSizes = {
  'xs': {
    right: 'pr-[1.875rem]',
    left: 'pl-[1.875rem]'
  },
  'sm': {
    right: 'pr-9',
    left: 'pl-8'
  },
  'icon-lg': {
    right: 'pr-9',
    left: 'pl-8'
  },
  'icon': {
    right: 'pr-9',
    left: 'pl-8'
  },
  'icon-sm': {
    right: 'pr-9',
    left: 'pl-8'
  },
  'md': {
    right: 'pr-9',
    left: 'pl-9'
  },
  'lg': {
    right: 'pr-10',
    left: 'pl-10'
  },
  'xl': {
    right: 'pr-10',
    left: 'pl-10'
  }
};
const translateClasses = '[&.position-left]:translate-x-2 [&.position-right]:-translate-x-2';
const adornmentVariants = cva('adornment inline-flex absolute pointer-events-none [&>*:not(.pointer-events-none)]:pointer-events-auto [&>*]:w-full', {
  variants: {
    size: {
      'xs': `w-3.5 text-xs ${translateClasses}`,
      'sm': `w-4 text-sm ${translateClasses}`,
      'md': `w-5 text-base ${translateClasses}`,
      'lg': `w-5 text-lg [&.position-left]:translate-x-3 [&.position-right]:-translate-x-3`,
      'icon-sm': `w-7 text-base ${translateClasses}`,
      'icon': `w-4 text-sm ${translateClasses}`,
      'icon-lg': `w-9 text-base ${translateClasses}`,
      'xl': `w-6 text-xl [&.position-left]:translate-x-3 [&.position-right]:-translate-x-3`
    },
    position: {
      left: 'position-left left-0 top-1/2 -translate-y-1/2',
      right: 'position-right right-0 top-1/2 -translate-y-1/2'
    }
  },
  defaultVariants: {
    size: 'sm'
  }
});
/** Used specifically for absolutely placing items on top or within
 * other atoms. For example:
 * <Input leftAdornment={<Adornment position><SearchIcon /></Adornment>} />
 */

function Adornment({
  position,
  size,
  className,
  children
}) {
  // TODO: change this and Input (or anywhere else it is used)
  // to flexbox-based layouts instead of absolute positioning.
  // The earlier the better so we have less potential for
  // breaking layouts
  return <div className={cn(adornmentVariants({
    size,
    position,
    className
  }))}>
      {children}
    </div>;
}
_c = Adornment;
function getSizeClass(size) {
  return paddingSizes[size];
}
export function useAdornments({
  leftAdornment,
  rightAdornment,
  size
}) {
  _s();
  return useMemo(() => {
    const hasLeft = leftAdornment != null && size !== 'icon';
    const hasRight = rightAdornment != null && size !== 'icon';
    const hasAdornment = hasLeft || hasRight;
    const sizeClasses = getSizeClass(size ?? 'md');
    return {
      left: hasLeft ? <Adornment size={size} position='left'>
          {leftAdornment}
        </Adornment> : null,
      right: hasRight ? <Adornment size={size} position='right'>
          {rightAdornment}
        </Adornment> : null,
      classNames: {
        /**
         * This class should be used on a paren of the
         * element which contains the .adornment class
         */
        adornedParentClass: cn({
          'l-adorned': hasLeft,
          'r-adorned': hasRight,
          'adorned relative': hasAdornment
        }),
        /**
         * This class should be used on the element
         * which will add padding to make room for
         * the adornment
         */
        adornmentPaddingClass: cn({
          [sizeClasses.left]: hasLeft,
          [sizeClasses.right]: hasRight
        })
      }
    };
  }, [leftAdornment, rightAdornment, size]);
}
_s(useAdornments, "nwk+m61qLgjDVUp4IGV/072DDN4=");
var _c;
$RefreshReg$(_c, "Adornment");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;